@import '../../styles/variables';

.Section {
  background-color: var(--color-light-1);

  &:nth-of-type(even) {
    background-color: #fff;
  }

  & > div {
    display: grid;
    margin: 0 auto;
    max-width: var(--max-width-page);
    padding: var(--spacing-4) var(--spacing-2);
    gap: var(--spacing-3);

    container: content / inline-size; // same as in side-nav

    & > h2 {
      // Subsequent section headings
      &:not(:first-of-type) {
        padding-top: 1.6rem;
      }
    }

    & > a {
      text-align: center;
      font-weight: 500;
    }
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Section > div {
    padding: var(--spacing-4) var(--spacing-3);
    gap: var(--spacing-3);
  }
}

@media only screen and (width >= $tablet-landscape) {
  .Section > div {
    padding: var(--spacing-5) var(--spacing-3);
    gap: var(--spacing-4);

    &.navIndent {
      padding-left: calc(var(--side-nav-width) + var(--spacing-6));
    }
  }
}

@media only screen and (width >= $max-width-page) {
  .Section > div {
    padding: var(--spacing-6) var(--spacing-4);
    gap: var(--spacing-4);
  }
}
